import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listContacts = ({pagination, userIds, snapshotDateTime, folderId, searchTerm}) => {
  return POST('exchange/contact/search', {pagination, userIds, snapshotDateTime, folderId, searchTerm: trimString(searchTerm)});
}

const listContactBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('exchange/contact/breadcrumbs', {userId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, code}) => {
  return POST('exchange/contact/export', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, RestoreType, code}) => {
  return POST('exchange/contact/restore', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), destinationId, snapshotId, selectedIds, RestoreType}, {code: code ? code : null})
}

export {
  listContacts,
  listContactBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
