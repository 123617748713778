<template>
  <div class="i-radio-buttons" :class="vertical">
    <div class="field-label" v-if="label">
      {{ label }}
    </div>
      <i v-if="labelInformation" class="label-information-header">{{ labelInformation }}</i>
    <div class="radio-options" v-for="option in options" :key="option.value">
      <label :class="{'disabled-label' : option.disabled}">
        <template v-if="!option.hideRadio">
          <input
            type="radio"
            :name="name"
            :checked="option.value === value"
            :value="option.value"
            :disabled="option.disabled"
            @input="onInput"
          />
        </template>
        {{ option.label }}
        <div v-if="option.labelInformation" class="label-information-option">
          <i>{{ option.labelInformation }}</i>
        </div>
      </label>
    </div>

    <div class="field-hint" v-if="hint">
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  name: "IRadioButtons",
  props: {
    label: {
      type: String
    },
    labelInformation: {
      type: String,
      required: false
    },
    hint: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    },
    vertical: {
      type: String, // horizontal|vertical
    },
    // New prop to hide the radio circle. It's optional and defaults to false.
    hideRadio: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
  }
}
</script>

<style lang="scss">
@import "IRadioButtons";
</style>
